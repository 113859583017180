<template>
  <section id="final-users">
    <b-row>
      <b-col>
        <h1>{{ $t("finalUsers.title") }}</h1>
        <h5 class="text-primary">
          {{ $t("finalUsers.edit") }}
        </h5>
      </b-col>
      <b-col>
        <div class="d-flex justify-content-end m-1">
          <router-link to="/users/final-users">
            <b-button variant="danger">
              {{ $t("dataGeneric.goBack") }}
            </b-button>
          </router-link>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col />
    </b-row>
    <b-card class="mt-5">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-tabs
            v-if="introspectionData.username && userData"
            v-model="tabs"
          >
            <b-tab active>
              <template #title>
                <feather-icon icon="UserIcon" />
                <span>{{ $t("User") }}</span>
              </template>
              <b-card-body>
                <b-card-title class="mt-1">
                  {{ $t("administrators.usIn") }}
                </b-card-title>
                <b-row>
                  <b-col md="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="User Name"
                      rules="required"
                    >
                      <b-form-group
                        v-b-tooltip.hover.bottom="introspectionData.username.description
                        "
                        :label="$t('administrators.user')"
                        :invalid-feedback="$t('required')"
                        :state="errors.length > 0 ? false : null"
                      >
                        <b-form-input
                          id="mc-user-name"
                          :value="cleanUsername(userData.username)"
                          autocomplete="new-password"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('administrators.user')"
                          maxlength="75"
                          disabled
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.firstName.description
                      "
                      :label="$t('administrators.name')"
                    >
                      <b-form-input
                        id="mc-first-name"
                        v-model="userData.firstName"
                        autocomplete="new-password"
                        :placeholder="$t('administrators.name')"
                        maxlength="30"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.lastName.description
                      "
                      :label="$t('administrators.lname')"
                    >
                      <b-form-input
                        id="mc-last-name"
                        v-model="userData.lastName"
                        autocomplete="new-password"
                        :placeholder="$t('administrators.lname')"
                        maxlength="30"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="3">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.nif.description
                      "
                      label="NIF/ CIF/ NIE"
                    >
                      <b-form-input
                        id="mc-user-nif"
                        v-model="userData.nif"
                        autocomplete="new-password"
                        placeholder="nif/ cif/ nie"
                        maxlength="9"
                        @input="validationDni(userData.nif)"
                      />
                      <small class="text-danger">{{ msgDni }}</small>
                    </b-form-group>
                  </b-col>

                  <b-col md="7">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.description.description
                      "
                      :label="$t('finalUsers.usdes')"
                    >
                      <b-form-input
                        id="mc-user-description"
                        v-model="userData.description"
                        autocomplete="new-password"
                        :placeholder="$t('finalUsers.usdes')"
                        maxlength="250"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.gender.description
                      "
                      :label="$t('finalUsers.usgen')"
                    >
                      <v-select
                        v-model="userData.gender"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="selectOptions(
                          introspectionData.gender.type.enumValues
                        )
                        "
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="mc-user-gender"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    v-if="subgroups"
                    md="4"
                  >
                    <b-form-group
                      v-if="hasSubgroups && checkPermissions('users.add_subgroup')"
                      :label="$t('Subgroup')"
                    >
                      <v-select
                        id="subgroup"
                        v-model="userData.subgroup"
                        :options="subgroups"
                        label="name"
                        :placeholder="$t('SinGrupoUsuario')"
                        aria-autocomplete="new-password"
                        autocomplete="new-password"
                        :reduce="option => option.id"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="AtSignIcon" />
                <span>{{ $t("administrators.contact") }}</span>
              </template>
              <b-card-body>
                <b-card-title class="mt-1">
                  {{ $t("administrators.contact2") }}
                </b-card-title>
                <b-row>
                  <b-col md="3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="User Name"
                      rules="required"
                    >
                      <b-form-group
                        v-b-tooltip.hover.bottom="introspectionData.email.description
                        "
                        label="Email"
                        :invalid-feedback="$t('required')"
                        :state="errors.length > 0 ? false : null"
                      >
                        <b-form-input
                          id="mc-user-email"
                          v-model="userData.email"
                          autocomplete="new-password"
                          :state="errors.length > 0 ? false : null"
                          placeholder="e-mail"
                          maxlength="75"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="2">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.phone.description"
                      :label="$t('finalUsers.Phone')"
                    >
                      <b-form-input
                        id="mc-user-phone"
                        v-model="userData.phone"
                        type="text"
                        maxlength="9"
                        :placeholder="$t('finalUsers.Phone')"
                        @input="validationPhone(userData.phone)"
                      />
                      <small class="text-danger">{{ msgPhone }}</small>
                    </b-form-group>
                  </b-col>

                  <b-col md="5">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.address.description
                      "
                      :label="$t('finalUsers.Address')"
                    >
                      <b-form-input
                        id="mc-user-address"
                        v-model="userData.address"
                        autocomplete="new-password"
                        :placeholder="$t('finalUsers.Address')"
                        maxlength="200"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="2">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.postalCode.description"
                      :label="$t('finalUsers.Code')"
                    >
                      <b-form-input
                        id="mc-postalCode-name"
                        v-model="userData.postalCode"
                        type="text"
                        maxlength="5"
                        :placeholder="$t('finalUsers.Code')"
                        @input="validationPostal(userData.postalCode)"
                      />
                      <small class="text-danger">{{ msgPostal }}</small>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.city.description
                      "
                      :label="$t('finalUsers.City')"
                    >
                      <b-form-input
                        id="mc-city-name"
                        v-model="userData.city"
                        autocomplete="new-password"
                        :placeholder="$t('finalUsers.City')"
                        maxlength="200"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.province.description
                      "
                      :label="$t('finalUsers.Province')"
                    >
                      <b-form-input
                        id="mc-province-name"
                        v-model="userData.province"
                        autocomplete="new-password"
                        :placeholder="$t('finalUsers.Province')"
                        maxlength="200"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.country.description
                      "
                      :label="$t('finalUsers.Country')"
                    >
                      <b-form-input
                        id="mc-country-name"
                        v-model="userData.country"
                        autocomplete="new-password"
                        :placeholder="$t('finalUsers.Country')"
                        maxlength="250"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="SettingsIcon" />
                <span>{{ $t("administrators.info") }}</span>
              </template>
              <b-card-body>
                <b-row>
                  <b-col md="4">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.dateJoined.description
                      "
                      :label="$t('administrators.date')"
                    >
                      <b-form-datepicker
                        disabled
                        :value="userData.dateJoined"
                        :readonly="true"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.lastLogin.description
                      "
                      :label="$t('administrators.last')"
                    >
                      <b-form-datepicker
                        disabled
                        :value="userData.lastLogin"
                        :readonly="true"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="3">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.pin.description
                      "
                      :label="$t('pin')"
                    >
                      <b-form-input
                        id="mc-pin-name"
                        v-model="userData.pin"
                        autocomplete="new-password"
                        :placeholder="$t('pin')"
                        type="number"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="2"
                    style="align-self: center;"
                  >
                    <b-form-group>
                      <b-form-checkbox
                        v-model="userData.isActive"
                        v-b-tooltip.hover.bottom="introspectionData.isActive.description
                        "
                        class="custom-control-success"
                        name="check-button"
                        switch
                        inline
                      >
                        {{ $t("administrators.usac") }}
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="2"
                    style="align-self: center;"
                  >
                    <b-form-group>
                      <b-form-checkbox
                        v-model="userData.confirmedEmail"
                        v-b-tooltip.hover.bottom="introspectionData.confirmedEmail.description
                        "
                        class="custom-control-success"
                        name="check-button"
                        switch
                        inline
                      >
                        {{ $t('emailCheck') }}
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="2"
                    style="align-self: center;"
                  >
                    <b-form-group>
                      <b-form-checkbox
                        v-model="userData.isLegalWarning"
                        v-b-tooltip.hover.bottom="introspectionData.isLegalWarning.description
                        "
                        class="custom-control-success"
                        name="check-button"
                        switch
                        inline
                      >
                        {{ $t('acept') }} <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="3"
                    style="align-self: center;"
                  >
                    <b-form-group>
                      <b-form-checkbox
                        v-model="userData.isAcceptAds"
                        v-b-tooltip.hover.bottom="introspectionData.isAcceptAds.description
                        "
                        class="custom-control-success"
                        name="check-button"
                        switch
                        inline
                      >
                        {{ $t('promoRecep') }} <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row />
              </b-card-body>
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="BookOpenIcon" />
                <span>{{ $t('address') }} </span>
              </template>
              <address-component
                :objeto="userData.userAddress == null ? [] : userData.userAddress.edges"
                :user="userData.id"
              />
            </b-tab>
            <div v-if="userData.devices">
              <b-tab v-if="userData.devices.edges.length != 0">
                <template #title>
                  <feather-icon icon="TabletIcon" />
                  <span>{{ $t('administrators.devices') }} </span>
                </template>
                <b-row style="height:621px">
                  <b-col
                    style="height:603px"
                    class="scroll-list list-group list-group-flush "
                  >
                    <b-table
                      striped
                      hover
                      outlined
                      :items="userData.devices.edges"
                      responsive
                      :fields="columns"
                    >
                      <template #cell(name)="data">
                        <div class="">
                          {{ data.item.node.name }}
                        </div>
                      </template>
                      <template #cell(type)="data">
                        <div class="">
                          {{ data.item.node.deviceType }}
                        </div>
                      </template>
                      <template #cell(version)="data">
                        <div class="">
                          {{ data.item.node.systemVersion }}
                        </div>
                      </template>
                      <template #cell(fcmTopics)="data">
                        <div class="">
                          {{ pipeToComma(data.item.node.fcmTopics) }}
                        </div>
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
              </b-tab>
            </div>
          </b-tabs>
        </b-form>
      </validation-observer>
      <b-button
        v-show="tabs == 2"
        variant="success"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right mt-5"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="updateUserData()"
      >
        <feather-icon icon="SaveIcon" />
        {{ $t("dataGeneric.save") }}
      </b-button>
      <b-button
        v-show="tabs == 0"
        variant="success"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right mt-5"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        :disabled="nifValidation"
        @click="updateUserData()"
      >
        <feather-icon icon="SaveIcon" />
        {{ $t("dataGeneric.save") }}
      </b-button>

      <b-button
        v-show="tabs == 1"
        :disabled="!boolPostal && !boolPhone ? false : true"
        variant="success"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right mt-5"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="updateUserData()"
      >
        <feather-icon icon="SaveIcon" />
        {{ $t("dataGeneric.save") }}
      </b-button>
    </b-card>
  </section>
</template>

<script>
import axios from '@axios'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import {
  identityDocument, postal, phoneNumber, cleanUsername, getSubgroups, checkPermissions, pipeToComma, showToast,
} from '@/store/functions'

import {
  BCard,
  BTabs,
  BTab,
  BCol,
  BRow,
  BCardTitle,
  BCardBody,
  BButton,
  BFormGroup,
  BFormInput,
  VBTooltip,
  BFormCheckbox,
  BFormDatepicker,
  BForm,
  BTable,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, length } from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive' // Notification
import { getUserData } from '@/auth/utils'
import addressComponent from './address.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardTitle,
    BCardBody,
    BTabs,
    BTab,
    BCol,
    BRow,
    BButton,
    BFormGroup,
    addressComponent,
    BFormInput,
    vSelect,
    BFormCheckbox,
    BFormDatepicker,
    BForm,
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      email,
      required,
      length,
      tabs: 0,
      msgDni: '',
      msgPostal: '',
      msgPhone: '',
      nifValidation: false,
      boolPostal: false,
      boolPhone: false,
      columns: [
        { key: 'name', label: this.$t('name') },
        { key: 'type', label: this.$t('type') },
        { key: 'version', label: this.$t('version') },
        { key: 'fcmTopics', label: 'FCM Topics' },
      ],
      hasSubgroups: false,
      subgroups: null,
      subgroup: null,
      userData: null,
      introspectionData: {},
    }
  },
  mounted() {
    const userData = getUserData()
    this.hasSubgroups = userData.profile.client.hasSubgroups
    if (this.hasSubgroups) {
      getSubgroups().then(result => {
        const { edges } = result.data.data.allSubgroups
        this.subgroups = edges.map(e => e.node)
        this.fetchData()
      }).catch(err => {
        console.log(err)
      })
    } else {
      this.fetchData()
    }
  },
  methods: {
    fetchData() {
      try {
        this.fetchUserQuery(router.currentRoute.params.id)
          .then(response => {
            this.userData = response.data.data.allVusers.edges[0].node
            const tempObject = {}
            /* eslint-disable no-unused-vars */
            Object.entries(response.data.data.allVusers.edges[0].node).forEach(
              ([key, value2]) => {
                /* eslint no-underscore-dangle: ["error", { "allow": ["__type"] }] */
                response.data.data.__type.fields.forEach(value => {
                  if (key === value.name) {
                    const mvalue = value
                    mvalue.value = value2
                    tempObject[key] = mvalue
                  }
                })
              },
            )
            this.introspectionData = tempObject
          })
          .catch(error => {
            console.log(error)
            this.userData = undefined
          })
      // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    validationDni(dni) {
      identityDocument(dni).then(result => {
        this.nifValidation = result
        this.msgDni = ''
      }).catch(err => {
        this.nifValidation = true
        this.msgDni = err
      })
    },
    validationPostal(post) {
      postal(post).then(result => {
        this.boolPostal = result
        this.msgPostal = ''
      }).catch(err => {
        this.boolPostal = true
        this.msgPostal = err
      })
    },
    validationPhone(phone) {
      phoneNumber(phone).then(result => {
        this.boolPhone = result
        this.msgPhone = ''
      }).catch(err => {
        this.boolPhone = true
        this.msgPhone = err
      })
    },
    updateUserData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const userData = {
            user: this.userData,
            fields: this.introspectionData,
          }
          this.mutateUser(userData)
            .then(response => {
              const { data, errors } = response.data
              if (data.updateVuser) {
                this.userData = data.updateVuser.vUser
                Object.entries(data.updateVuser.vUser).forEach(
                  ([key, value2]) => {
                    if (key !== 'id') this.introspectionData[key].value = value2
                  },
                )
                showToast(this.$t('success'), 1, this)
              } else {
                errors.forEach(e => {
                  showToast(this.$t('error'), 2, this)
                })
              }
            })
            .catch(error => {
              showToast(this.$t('error'), 2, this)
            })
        } else {
          Object.keys(this.$refs.simpleRules.$data.errors).map(key => {
            if (this.$refs.simpleRules.$data.errors[key][0]) {
              showToast(this.$refs.simpleRules.$data.errors[key][0], 2, this)
            }
            return key
          })
        }
      })
    },
    fetchUserQuery(id) {
      const userData = getUserData()
      return new Promise((resolve, reject) => {
        axios
          .post('', {
            query: `
              {
                __type(name: "VuserNode") {
                  fields {
                    name
                    description
                    type {
                      kind
                      ofType {
                        name
                      }
                      name
                      description
                      enumValues {
                        description
                        name
                      }
                    }
                  }
                }
                allVusers(id: "${id}",
                client:"${userData.profile.client.id}"
                ) {
                  
                    edges {
                      node {
                        id          
                        username
                        firstName
                        lastName
                        email
                        isActive
                        dateJoined
                        lastLogin
                        gender
                        phone
                        address
                        city
                        province
                        postalCode
                        country
                        nif
                        pin
                        description
                        confirmedEmail
                        isLegalWarning
                        isAcceptAds
                        subgroup{
                          id
                          name
                        }
                        userAddress {
                          edges {
                            node {
                              id
                              name
                              lastName
                              addressName
                              type
                              province
                              city
                              address
                              postalCode
                              country {
                                id
                              }
                              nif
                              phone
                              isActive
                            }
                          }
                        }
                        devices {
                          edges {
                            node {
                              name
                              deviceType
                              systemVersion
                              fcmTopics
                            }
                          }
                        }
                      }
                    }
                  }
              }
            `,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    mutateUser(data) {
      const { user } = data
      const { fields } = data
      let { parsedInputs, vars } = this.generateInputs(user, fields, ['dateJoined', 'lastLogin'])
      return new Promise((resolve, reject) => {
        if (parsedInputs.includes('subgroup:null')) {
          parsedInputs = parsedInputs.replace(/subgroup\s*:\s*null/, 'subgroup:$subgroup')
          vars += '$subgroup: ID'
        }
        axios
          .post('', {
            query: `
              mutation mutateVUser${vars ? '(' : ''}${vars}${vars ? ')' : ''}{
                updateVuser(id:"${user.id}", input: ${parsedInputs}) {
                  vUser{
                    id
                    username
                    firstName
                    lastName
                    email
                    isActive
                    dateJoined
                    lastLogin
                    gender
                    phone
                    address
                    city
                    province
                    postalCode
                    country
                    nif
                    pin
                    description
                    confirmedEmail
                    isLegalWarning
                    isAcceptAds
                    subgroup{
                      id
                      name
                    }
                  }
                }
              }
            `,
            variables: {
              subgroup: null,
              nullInt: null,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    selectOptions(data) {
      const mappedData = data.map(item => (
        {
          value: item.name,
          label: item.description,
        }
      ))
      return mappedData
    },
    generateInputs(user, introspectionUser, ignoreFields) {
      const inputs = { ...user }
      delete inputs.id
      ignoreFields.forEach(element => {
        delete inputs[element]
      })
      // Only send changed fields
      Object.keys(inputs).map(key => {
        if (inputs[key] === introspectionUser[key].value) {
          delete inputs[key]
        }
        return key
      })
      const json = JSON.stringify(inputs)
      let parsedInputs = json.replace(/"([^"]+)":/g, '$1:')
      let vars = ''
      Object.keys(inputs).map(key => {
        if (introspectionUser[key].type.kind === 'ENUM' && introspectionUser[key].type.enumValues) {
          introspectionUser[key].type.enumValues.map(item => {
            parsedInputs = parsedInputs.replace(`"${item.name}"`, `${item.name}`)
            return item
          })
        }
        if (introspectionUser[key].type.name === 'Int') {
          parsedInputs = parsedInputs.replace(`${key}:"${user[key]}"`, `${key}:${user[key]}`)
          if (user[key] === null || user[key] === '') {
            parsedInputs = parsedInputs.replace(`${key}:`, `${key}:$nullInt`)
          }
        }
        return key
      })
      if (parsedInputs.indexOf('$nullInt') !== -1) {
        vars = vars.concat('$nullInt:Int ')
      }
      return { parsedInputs, vars }
    },
    cleanUsername,
    pipeToComma,
    checkPermissions,
  },
}
</script>
<style>
.address-box {
  /* height: 70px; */
  padding: 15px;
  opacity: 0.8;
  border: 1px solid white
}
</style>
